import React from 'react';
import Layout from '../components/Layout';
import { StyledLink } from '../components/styledLinkAnchor';

const NotFound = () => {
  return (
    <Layout>
      <h3>Page Not Found</h3>
      <p>Unfortunately what you are looking for is not here.</p>
      <StyledLink to="/">Back to Home page</StyledLink>
    </Layout>
  );
};

export default NotFound;
